








import { Component, Vue } from "vue-property-decorator";
import BazzarApi from "@/api/bazaar.api";
import { countdown, observer } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
@Component({})
export default class SmallPop extends Vue {
  Data: any = null;
  globalState = getModule(GlobalStateStore);
  countdown: any = null;
  Timer: any = null;
  get Tran(){
    return `opacity: ${this.globalState.transparence}`
  }
  async init(){
    this.Data = await BazzarApi.getRegisteredWelfare();
    const t = Math.ceil(new Date().getTime() / 1000) + this.Data.countdown;
    this.Timer = setInterval(() => {
      const { day,hour, minute, second } = countdown(t);
      let hours = hour
      if(Number(day)>0){
       hours = String(Number(hour)+(24*Number(day)))
      }
      if(Number(day)==0&&Number(hour)==0&&Number(minute)==0&&Number(second)==0){
        clearInterval(this.Timer)
        this.countdown = null
        InteractionLib.noticeCloseSmallWindow()
        return
      }
      this.countdown = `
                <div >
                <span class="">${hours}</span><span class="maohao">:</span><span class="">${minute}</span><span class="maohao">:</span><span class="">${second}</span>
                </div>
        `;
    }, 1000);
  }
  async created() {
    this.init()
  }
  close() {}
  mounted(){
    document.querySelector("html")?.classList.remove("__theme_white");
    observer("refreshInit", async() => {
       if (!this.globalState.userToken) {
        await this.globalState.getUserInfo();
      }
      clearInterval(this.Timer)
      this.init()
      // InteractionLib.noticeCloseSmallWindow()
    });
  }
  updateOpenNewWindow() {
    if (this.globalState.userToken) {
        return InteractionLib.openWebView(
          `${location.origin}/timit-welfare-page`
        );
    }
    InteractionLib.login();
  }
}
